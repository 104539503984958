import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyDNuqgyngbzgUTvAg2DC9w0MgsN3i2Y5JA",
  authDomain: "yotap-test.firebaseapp.com",
  databaseURL: "https://yotap-test-default-rtdb.firebaseio.com",
  projectId: "yotap-test",
  storageBucket: "yotap-test.appspot.com",
  messagingSenderId: "718658803324",
  appId: "1:718658803324:web:226e076e8980fa6cbd9bf9",
  measurementId: "G-64NQMRPNNY",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export { auth };
