import React from "react";
import "./ResetSuccessfulPage.css";
import logo from "../assets/images/circo.png"; // Import logo image

function ResetSucessfulPage() {
  return (
    <div className="container">
      <div className="reset-section">
        <div className="icon">
          <span className="checkmark">✔</span>
        </div>
        <div className="text">
          <h1>Password reset successfully</h1>
          <a href="https://www.getcirco.com/" className="login-link">
            Login now
          </a>
        </div>
      </div>
    </div>
  );
}

export default ResetSucessfulPage;
