import React from "react";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
// import ForgotPasswordPage from "../pages/ForgotPasswordPage";
// import Homepage from "../pages/Homepage";
// import Loginpage from "../pages/Loginpage";
// import NotfoundPage from "../pages/NotfoundPage";
// import Profilepage from "../pages/Profilepage";
// import Registerpage from "../pages/Registerpage";
import ResetPasswordPage from "../pages/ResetPasswordPage";
import ResetSuccessfulPage from "../pages/ResetSuccessfulPage";
// import TestPage from "../pages/TestPage";

export default function AppRouter(props) {
  return (
    <>
      <Router>
        <Routes>
          {/* <Route exact path="/" component={Homepage} /> */}
          {/* <ProtectedRoute exact path="/login" component={Loginpage} />
          <ProtectedRoute exact path="/register" component={Registerpage} />
          <ProtectedRoute exact path="/profile" component={Profilepage} />
          <ProtectedRoute exact path="/test" component={TestPage} />
          <ProtectedRoute
            exact
            path="/forgot-password"
            component={ForgotPasswordPage}
          /> */}
          <Route
            exact
            path="/reset-successful"
            element={<ResetSuccessfulPage />}
          />
          <Route exact path="/reset-password" element={<ResetPasswordPage />} />
          {/* <Route exact path="*" component={NotfoundPage} /> */}
        </Routes>
      </Router>
    </>
  );
}

// function ProtectedRoute(props) {
//   const { currentUser } = useAuth();
//   const { path } = props;
//   console.log("path", path);
//   const location = useLocation();
//   console.log("location state", location.state);

//   if (
//     path === "/login" ||
//     path === "/register" ||
//     path === "/forgot-password" ||
//     path === "/reset-password"
//   ) {
//     return currentUser ? (
//       <Redirect to={location.state?.from ?? "/profile"} />
//     ) : (
//       <Route {...props} />
//     );
//   }
//   return currentUser ? (
//     <Route {...props} />
//   ) : (
//     <Redirect
//       to={{
//         pathname: "/login",
//         state: { from: path },
//       }}
//     />
//   );
// }
