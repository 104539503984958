import React, { useState, useEffect } from "react";
import "./ResetPasswordPage.css";
import logo from "../assets/images/circo.png"; // Import logo image
import { useHistory, useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useAuth } from "../contexts/AuthContext";

import errorIcon from "../assets/icons/error-svgrepo-com.svg";
import checkIcon from "../assets/icons/success-svgrepo-com.svg";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function ResetPasswordPage() {
  const { resetPassword } = useAuth();
  const query = useQuery();
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState(null);
  const oobCode = query.get("oobCode");
  const notifyErr = (message) => toast.error(message);
  const notifySuccess = (message) => toast.success(message);

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
    validatePassword(event.target.value); //To validate password
  };

  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
  };

  const validatePassword = (password) => {
    // const email = ""; * Save for later if need
    // const name = "";

    setPasswordValidations({
      length: password.length >= 8,
      lowercase: /[a-z]/.test(password),
      uppercase: /[A-Z]/.test(password),
      number: /[0-9]/.test(password),
      // emailOrName: !(email && password.includes(email)) && !(name && password.includes(name)), *Save for later if need
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (password !== confirmPassword) {
      // alert("Passwords do not match!");
      notifyErr("Passwords do not match!");
      return;
    }
    if (!Object.values(passwordValidations).every(Boolean)) {
      notifyErr("Password does not meet all criteria!"); // New validation check
      return;
    }
    try {
      await resetPassword(oobCode, password);
      notifySuccess("Password reset successfully");
      navigate("/reset-successful");
    } catch (error) {
      notifyErr(error.message);
      setError(error.message);
    }
  };

  const [passwordValidations, setPasswordValidations] = useState({
    //password validation criteria
    length: false,
    lowercase: false,
    uppercase: false,
    number: false,

    // emailOrName: true, *Save for later if need
  });

  console.log("executed!!");
  return (
    <div className="App">
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div className="reset-password-container">
        <img src={logo} alt="Site Logo" className="site-logo" />
        <h1>Reset your password</h1>

        <form onSubmit={handleSubmit}>
          <input
            type="password"
            placeholder="Enter your new password"
            required
            onChange={handlePasswordChange}
          />
          <input
            type="password"
            placeholder="Confirm your new password"
            onChange={handleConfirmPasswordChange}
            required
          />
          <div className="password-validation-container">
            <div className="password-validation">
              <p className={passwordValidations.length ? "valid" : "invalid"}>
                <img
                  src={passwordValidations.length ? checkIcon : errorIcon}
                  alt={passwordValidations.length ? "Success" : "Error"}
                />
                At least 8 characters
              </p>

              <p
                className={passwordValidations.lowercase ? "valid" : "invalid"}
              >
                <img
                  src={passwordValidations.lowercase ? checkIcon : errorIcon}
                  alt={passwordValidations.lowercase ? "Success" : "Error"}
                />
                Contains a lowercase letter
              </p>

              <p
                className={passwordValidations.uppercase ? "valid" : "invalid"}
              >
                <img
                  src={passwordValidations.uppercase ? checkIcon : errorIcon}
                  alt={passwordValidations.uppercase ? "Success" : "Error"}
                />
                Contains an uppercase letter
              </p>

              <p className={passwordValidations.number ? "valid" : "invalid"}>
                <img
                  src={passwordValidations.number ? checkIcon : errorIcon}
                  alt={passwordValidations.number ? "Success" : "Error"}
                />
                Contains a number
              </p>

              {/* <p className={passwordValidations.emailOrName ? "valid" : "invalid"}>
              <img src={passwordValidations.emailOrName ? checkIcon : errorIcon} alt={passwordValidations.emailOrName ? "Success" : "Error"} />
                Can't contain your name or email address
              </p> *Save for later if need */}
            </div>
          </div>

          <button type="submit">Change Password</button>
        </form>
      </div>
    </div>
  );
}
